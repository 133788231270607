<template>
  <b-modal
    id="editAccountName"
    modal-class="giga-modal"
    dialog-class="modal-lg"
    content-class="text-center pb-2"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    hide-footer
    lazy
  >
    <div class="gigawhat-modal">
      <div class="modal-heading">
        <h2 class="modal-title">Compléter vos informations</h2>
        <p class="modal-description">
          Veuillez renseigner votre nom et prénom pour continuer
        </p>
      </div>

      <user-config-infos :$v="$v" :userForm="userForm"></user-config-infos>

      <div class="gigwhat-model_footer mt-5">
        <div class="half-footer left-content"></div>
        <div class="half-footer right-content">
          <div class="giga-button">
            <button
              id="btn-submit"
              :disabled="$v.userForm.$invalid"
              class="giga-green_button no-icon"
              @click="saveUser()"
            >
              Terminer
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import UserConfigInfos from "@/views/Account/NewUser/UserConfigInfos.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserConfig",
  props: ["userForm", "$v"],
  components: { UserConfigInfos },
  userForm: {
    lastName: "",
    firstName: "",
    email: ""
  },
  computed: {
    ...mapGetters("user", { currentTab: "getCurrentTab" }),
    ...mapGetters("account", { currentUser: "getCurrentUser" })
  },
  methods: {
    saveUser() {
      this.$emit("confirmClick");
    }
  }
};
</script>
