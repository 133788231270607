<template>
  <div class="giga-fields">
    <div class="giga-input_field half-field">
      <label class="input-field_label">Nom</label>
      <div class="input-field_area">
        <input
          id="input-lastName"
          type="text"
          v-model="$v.userForm.lastName.$model"
        />
      </div>
      <div
        v-if="$v.userForm.lastName.$anyDirty && $v.userForm.lastName.$invalid"
      >
        <small
          class="form-text text-danger"
          v-if="!$v.userForm.lastName.required"
        >
          {{ $t("required_field") }}
        </small>
      </div>
    </div>
    <div class="giga-input_field half-field">
      <label class="input-field_label">Prénom</label>
      <div class="input-field_area">
        <input
          id="input-firstName"
          type="text"
          v-model="$v.userForm.firstName.$model"
        />
      </div>
      <div
        v-if="$v.userForm.firstName.$anyDirty && $v.userForm.firstName.$invalid"
      >
        <small
          class="form-text text-danger"
          v-if="!$v.userForm.firstName.required"
        >
          {{ $t("required_field") }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserConfigInfos",
  props: ["userForm", "$v"],
  computed: {
    ...mapGetters("agence", ["getAgences", "getAgence"]),
    ...mapGetters("account", { currentUser: "getCurrentUser" })
  }
};
</script>
