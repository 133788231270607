var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"giga-simulation_card",class:{
        'active-alert':
          _vm.simulation.status === 'CARRIED_OUT' &&
          _vm.canValidateSimulation &&
          !_vm.simulation.isDraft
      },attrs:{"id":("sim-" + (_vm.simulation.name))}},[(
          _vm.simulation.status === 'CARRIED_OUT' &&
            _vm.canValidateSimulation &&
            !_vm.simulation.isDraft
        )?_c('div',{staticClass:"giga-simulation_alert"},[_c('div',{staticClass:"giga-simulation_alert_8"},[_c('label',{staticClass:"giga-simulation_alert_title"},[_vm._v(_vm._s(_vm.$t("send_to_leyton_or_delete_your_project")))])]),_c('div',{staticClass:"giga-simulation_alert_4"},[_c('ul',{staticClass:"giga-simulation_alert_actions"},[_c('li',{staticClass:"alert-action_item"},[_c('div',{staticClass:"giga-button"},[_c('button',{staticClass:"giga-green_button no-icon",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openConfirmModal('confirm-validation', _vm.simulation.id)}}},[_vm._v(" "+_vm._s(_vm.$t("send"))+" ")])])]),_c('li',{staticClass:"alert-action_item"},[_c('div',{staticClass:"giga-button"},[_c('button',{staticClass:"giga-white_outlined_button no-icon",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openConfirmModal('confirm-lose', _vm.simulation.id)}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])])])])])]):_vm._e(),_c('div',{staticClass:"giga-flexible_card",class:{ 'is-draft': _vm.simulation.isDraft },style:({
          backgroundColor:
            _vm.simulation.status === 'CANCEL'
              ? '#fef7f7'
              : _vm.simulation.isDraft
              ? '#f6f9fc'
              : '',
          border: _vm.simulation.status === 'CANCEL' ? 'solid 1.6px #ffdfd6' : ''
        })},[_c('div',{staticClass:"giga-flexible_card_header"},[_c('div',{staticClass:"HeaderClass"},[_c('h3',{staticClass:"giga-flexible_card_title"},[_vm._v(_vm._s(_vm.simulation.name)+" "),((_vm.simulation.cofracState === 'BOTH' || _vm.simulation.cofracState === 'ONLY_COFRAC')
                                                      &&!_vm.simulation.isDraft && _vm.simulation.status !== 'CANCEL' &&
                                                      (_vm.simulation.status === 'NEW' || _vm.simulation.status === 'CARRIED_OUT' || _vm.simulation.status === 'TO_BE_TRANSFORMED'))?_c('span',{staticClass:"tooltip-container"},[_c('svg',{staticClass:"warning-icon",attrs:{"viewBox":"0 0 15 15","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticClass:"triangle",attrs:{"d":"M7.5 1.2\n                         C7 1.2 6.7 1.5 6.5 1.8\n                         L0.8 11.2\n                         A1.5 1.5 0 0 0 2.2 13\n                         H12.8\n                         A1.5 1.5 0 0 0 14.2 11.2\n                         L8.5 1.8\n                         C8.3 1.5 8 1.2 7.5 1.2Z"}}),_c('path',{staticClass:"exclamation-mark",attrs:{"d":"M7.5 3.5C7.75 3.5 8 3.75 8 4L7.8 8C7.8 8.25 7.6 8.5 7.5 8.5C7.4 8.5 7.2 8.25 7.2 8L7 4C7 3.75 7.25 3.5 7.5 3.5Z"}}),_c('circle',{staticClass:"exclamation-mark",attrs:{"cx":"7.5","cy":"10.5","r":"0.8"}})]),_c('span',{staticClass:"tooltip-text"},[_vm._v(_vm._s(_vm.$t("quality_control_required")))])]):_vm._e()]),(_vm.simulation.isDraft && _vm.simulation.status !== 'CANCEL')?_c('span',{staticClass:"draft-badge"},[_vm._v("Brouillon")]):_vm._e(),(_vm.simulation.status === 'CANCEL')?_c('span',{staticClass:"perdu-badge"},[_vm._v("Perdu")]):_vm._e()]),_c('span',{staticClass:"giga-flexible_card_badge"},[_vm._v(_vm._s(_vm.simulation.numberOfNotifications))]),_c('ul',{staticClass:"giga-simulation_process_statuses"},[_c('li',{staticClass:"giga-simulation_status",class:[
                _vm.simulation.status == 'NEW' ? 'done' : '',
                _vm.simulation.status == 'CARRIED_OUT' ? 'done' : '',
                _vm.simulation.status == 'TO_BE_TRANSFORMED' ? 'done' : ''
              ]},[_c('span',{style:({
                  backgroundColor: _vm.simulation.isDraft
                    ? '#f6f9fc'
                    : _vm.simulation.status === 'CANCEL'
                    ? '#fef7f7'
                    : ''
                })},[_vm._v("Estimation Volume")])]),_c('li',{staticClass:"giga-simulation_status",class:[
                _vm.simulation.status == 'CARRIED_OUT' ? 'done' : '',
                _vm.simulation.status == 'TO_BE_TRANSFORMED' ? 'done' : ''
              ]},[_c('span',{style:({
                  backgroundColor: _vm.simulation.isDraft
                    ? '#f6f9fc'
                    : _vm.simulation.status === 'CANCEL'
                    ? '#fef7f7'
                    : ''
                })},[_vm._v("Volume Estimé")])]),_c('li',{staticClass:"giga-simulation_status",class:{ done: _vm.simulation.status == 'TO_BE_TRANSFORMED' }},[_c('span',{style:({
                  backgroundColor: _vm.simulation.isDraft
                    ? '#f6f9fc'
                    : _vm.simulation.status === 'CANCEL'
                    ? '#fef7f7'
                    : ''
                })},[_vm._v("En traitement")])])])]),_c('div',{staticClass:"giga-flexible_card_body"},[_c('div',{staticClass:"giga-flexible_card_details"},[(
                _vm.simulation.status !== 'CARRIED_OUT' &&
                  _vm.simulation.status !== 'TO_BE_TRANSFORMED'
              )?_c('ul',{staticClass:"giga-simulation_card_details"},[_c('li',{staticClass:"giga-simulation_card_detail"},[_c('label',[_vm._v(_vm._s(_vm.$t("estimated_end_date")))]),_c('p',[_vm._v(" "+_vm._s(_vm.simulation.deliveryDate > 0 ? _vm.moment(_vm.simulation.deliveryDate, "x") .locale("fr") .format("DD MMMM YYYY") : _vm.moment() .locale("fr") .format("DD MMMM YYYY"))+" ")])])]):_vm._e(),(
                _vm.simulation.status === 'CARRIED_OUT' ||
                  _vm.simulation.status === 'TO_BE_TRANSFORMED'
              )?_c('ul',[_c('li',{staticClass:"giga-simulation_card_detail"},[_c('label',[_vm._v(_vm._s(_vm.$t("Volume théorique classique")))]),_c('p',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.simulation.expectedThClassicVolume))+" kWh cumac ")])]),_c('li',{staticClass:"giga-simulation_card_detail"},[_c('label',[_vm._v(_vm._s(_vm.$t("Volume théorique précaire")))]),_c('p',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.simulation.expectedThPrecarityVolume))+" kWh cumac ")])]),_c('li',{staticClass:"giga-simulation_card_detail"},[_c('label',[_vm._v(_vm._s(_vm.$t("economic_valorisation")))]),(_vm.simulation.economicValorisation)?_c('p',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.simulation.economicValorisation))+" € ")]):_c('p',[_vm._v("En cours")])])]):_vm._e()]),_c('div',{staticClass:"giga-flexible_card_actions"},[_c('div',{staticClass:"giga-flexible_card_icons"},[_c('button',{staticClass:"simulation-card_details_button",on:{"click":function($event){return _vm.onClickDetailSimulation(_vm.simulation.id)}}},[_c('img',{staticClass:"detail-icon",attrs:{"src":require("@/assets/images/icons/mi-list.svg"),"alt":"detail"}})]),(
                  !_vm.isVIEWER &&
                    _vm.simulation.status !== 'CANCEL' &&
                    _vm.simulation.status !== 'TO_BE_TRANSFORMED'
                )?_c('button',{staticClass:"simulation-card_edit_button",on:{"click":function($event){return _vm.onRowSelected(_vm.simulation.id)}}},[_c('img',{staticClass:"edit-icon",attrs:{"src":require("@/assets/images/icons/pepicons-pop-pen.svg"),"alt":"Edit"}})]):_vm._e(),(
                  _vm.simulation.status !== 'CANCEL' &&
                    _vm.simulation.status !== 'TO_BE_TRANSFORMED'
                )?_c('button',{staticClass:"work-card_dlete_button",on:{"click":function($event){return _vm.openConfirmModal('confirm-lose', _vm.simulation.id)}}},[_c('img',{staticClass:"delete-icon",attrs:{"src":require("@/assets/images/icons/gravity-ui-trash-bin.svg"),"alt":"delete"}})]):_vm._e()])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }